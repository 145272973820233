(function () {
  'use strict';

  angular
    .module('app')
    .config(appRouteConfig);

  appRouteConfig.$inject = ['$urlRouterProvider', '$stateProvider'];

  function appRouteConfig($urlRouterProvider, $stateProvider) {
    $stateProvider
      .state('publishedProject', {
        url: '/:userId/:projectName/:projectId',
        component: 'publishedTrancriptPage',
        resolve: {
          project: publicProjectResolve
        }
      })
      .state('app.noFound', {
        url: '/no-found',
        component: 'noFoundOopsPublished'
      });

    $urlRouterProvider.otherwise('/');
  }

  publicProjectResolve.$inject = ['$stateParams', 'projectPublishedService', 'sharedProjectsPublishedService', '$window'];

  function publicProjectResolve($stateParams, projectPublishedService, sharedProjectsPublishedService, $window) {
    return projectPublishedService.getOne($stateParams.projectId).then(response => {
      const projectName = $stateParams.projectName;
      if (projectName !== sharedProjectsPublishedService.getProjectNamePartLink(response)) {
        throw new Error('Incorrect public url');
      }
      return response;
    }).catch(() => $window.location.replace('/no-found'));
  }

})();
