(function () {
  'use strict';

  angular
    .module('app')
    .controller('appController', AppController);

  AppController.$inject = ['$window', 'ogUrl', '$stateParams', '$timeout'];

  function AppController($window, ogUrl, $stateParams, $timeout) {
    const me = this;
    me.login = login;

    init();

    function init() {
      me.logoLink = ogUrl;
      $timeout(() => isPublicTranscriptPage(), 2500);
    }

    function login() {
      $window.location.href = ogUrl + 'signin';
    }

    function isPublicTranscriptPage() {
       if ($stateParams.projectId && $stateParams.projectName && $stateParams.userId) {
        me.isStickyActive = true;
      }
    }

  }
})();
