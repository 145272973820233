angular.module("ngConstants", [])

.constant("host", "https://simonsaysai.com/")

.constant("ogUrl", "https://app.simonsaysai.com/")

.constant("PUBLIC_SITE_URL", "https://public.simonsaysai.com")

.constant("STORAGE_BASE_URL", "https://s3-us-west-2.amazonaws.com/prod-public-storage/")

;