(function () {
  'use strict';

  angular
    .module('app')
    .factory('transcriptionRestService', transcriptionRestService);

  transcriptionRestService.$inject = ['STORAGE_BASE_URL', '$http']

  function transcriptionRestService(STORAGE_BASE_URL, $http) {
    const service = {
      getByMedia: getByMedia
    };
    return service;
    ///////////////////////

    function getByMedia(mediaId) {
      return $http.get(STORAGE_BASE_URL + 'transcripts/' + mediaId).then(response => response.data).catch(reason => console.error(reason));
    }

  }
})();
