(function () {
  'use strict';

  angular
    .module('app', [
      'ngConstants',
      'ngRoute',
      'ngSanitize',
      'ngAnimate',
      'ngMaterial',
      'ui.router',
      'angular-loading-bar',
      'angular-svg-round-progressbar',
      'com.2fdevs.videogular',
      'com.2fdevs.videogular.plugins.controls',
      'com.2fdevs.videogular.plugins.overlayplay',
      'com.2fdevs.videogular.plugins.poster',
      'com.2fdevs.videogular.plugins.buffering',
      'uk.ac.soton.ecs.videogular.plugins.cuepoints',
      'angularSpinner',
      'slickCarousel',
      'sticky',
      'ui.bootstrap',
      'toastr',
      'angularMoment',
      'ui.mask',
      'ui.select',
      'ng-visible-invisible',
      'ai.simonCommonUi'
    ]);

})();
