(function () {
  'use strict';

  angular
    .module('app')
    .factory('sharedProjectsPublishedService', sharedProjectsPublishedService);

  sharedProjectsPublishedService.$inject = ['stringHelperService'];

  function sharedProjectsPublishedService(stringHelperService) {
    const service = {
      getProjectNamePartLink: getProjectNamePartLink,
    };
    return service;
    ////////////////

    function removeUnsafeChars(stringToReplace) {
      const result = stringHelperService
        .replaceAll(stringHelperService
          .removeExtraWhiteSpaces(stringHelperService
            .removeAllSpecialChars(stringToReplace, true))
          .trim().toLowerCase(), ' ', '-');
      return result;
    }

    function getProjectNamePartLink(project) {
      return removeUnsafeChars(project.name);
    }

  }
})();
