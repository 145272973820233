(function () {
  'use strict';

  angular
    .module('app')
    .factory('languageRestService', languageRestService);

  function languageRestService() {
    return {
      getAll: getAll
    };

    function getAll() {
      return new Promise((resolve, reject) => resolve([]));
    }

  }
})();
