(function () {
  'use strict';
  angular
    .module('app')
    .config(appConfig);

  appConfig.$inject = ['$httpProvider', '$locationProvider', '$qProvider', 'hotkeysProvider', 'toastrConfig'];

  function appConfig($httpProvider, $locationProvider, $qProvider, hotkeysProvider, toastrConfig) {
    $locationProvider.html5Mode(true);
    $locationProvider.hashPrefix('');

    hotkeysProvider.templateFooter = `
          <div class="smart-commands-help-footer">
          <h4 class="cfp-hotkeys-title"> What can I do on this page? </h4>
          <ol>
              <li> Play your media and adjust the playback speed, edit the text, and add speaker labels.</li>
              <li> Export your transcript.</li>
              <li> Share your project with your team (monthly subscription required; contact us to activate the feature).</li>
              <li> Synchronize timecode.</li>
              <li> Search for keywords, bookmark rows, and annotate.</li>
          </ol>
          </div>`;

    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    $qProvider.errorOnUnhandledRejections(false);

    angular.extend(toastrConfig, {
      allowHtml: true,
      closeButton: true,
      closeHtml: '<button>&times;</button>',
      timeOut: 0,
      extendedTimeOut: 0,
      tapToDismiss: false,
      preventDuplicates: false,
      preventOpenDuplicates: true,
      positionClass: 'toast-top-center'
    });

  }
})();
