(function () {
  'use strict';

  angular
    .module('app')
    .component('publishedTrancriptPage', {
      controller: PublishedTrancriptPageController,
      bindings: {
        project: '<'
      },
      templateUrl: 'app/pages/published-transcript-page/published-transcript-page.component.html'
    });

  PublishedTrancriptPageController.$inject = ['$rootScope', '$uibModal', 'transcriptionRestService', '$window',
  '$stateParams', 'languageRestService', 'PUBLIC_SITE_URL', 'ogUrl'];

  function PublishedTrancriptPageController($rootScope, $uibModal, transcriptionRestService, $window,
    $stateParams, languageRestService, PUBLIC_SITE_URL, ogUrl) {
    const me = this;

    me.$onInit = onInit;
    me.$onDestroy = onDestroy;
    me.getProjectLanguageDirection = getProjectLanguageDirection;
    me.onMediaSelect = onMediaSelect;
    me.onClickExportButton = onClickExportButton;
    me.onClickHome = onClickHome;
    me.isStickyActive = true;
    me.waveSoundOptions = {
      waveColor: '#BAA6CB',
      progressColor: '#7e56a2'
    };

    function onInit() {
      const selectorOgTitle = 'meta[property="og:title"]';
      const selectorOgUrl = 'meta[property="og:url"]';
      const selectorOgImage = 'meta[property="og:image"]';
      const selectorTwitterTitle = 'meta[name="twitter:title"]';
      const selectorTwitterImage = 'meta[name="twitter:image"]';

      const title = $stateParams.projectName + ' | Automatically transcribe your media.';
      const url = PUBLIC_SITE_URL + '/' + $stateParams.userId + '/' + $stateParams.projectName + '/' + $stateParams.projectId;
      const image = me.project.thumb !== 'img/poster-audio.png' ? me.project.thumb : PUBLIC_SITE_URL + '/img/landing/simon_says_parrot.png';

      document.title = title;
      setContentAttribute(document.querySelector(selectorOgTitle), title);
      setContentAttribute(document.querySelector(selectorOgUrl), url);
      setContentAttribute(document.querySelector(selectorOgImage), image);
      setContentAttribute(document.querySelector(selectorTwitterTitle), title);
      setContentAttribute(document.querySelector(selectorTwitterImage), image);

      $rootScope.isStickyActive = false;
      languageRestService.getAll().then(languages => me.languages = languages);
      if (me.project.medias && me.project.medias[0]) {
        onMediaSelect(me.project.medias[0]);
      }
    }

    function setContentAttribute(element, content) {
      if (element && element.setAttribute) {
        element.setAttribute('content', content);
      }
    }

    function onDestroy() {
      $rootScope.isStickyActive = false;
    }

    function setContentAttribute(element, content) {
      if (element && element.setAttribute) {
        element.setAttribute('content', content);
      }
    }

    function getProjectLanguageDirection(project) {
      if (angular.isArray(me.languages) && project.languageRegionCode) {
        const languageCode = project.languageRegionCode.split(/-(.+)/)[0];
        const language = me.languages.find(languageItem => languageItem.code === languageCode);
        return languague ? language.direction : '';
      }
      return '';
    }

    function onClickExportButton() {
      $uibModal.open({
        animation: true,
        component: 'exportTranscriptionModal',
        resolve: {
          mediaCount: () => angular.isArray(me.project.medias) ? me.project.medias.length : 0,
          currentMediaId: () => me.currentMedia.id,
          project: () => me.project
        }
      });
    }

    function onMediaSelect(selectedMedia) {
      me.currentMedia = selectedMedia;
      showMediaTranscriptions(me.currentMedia);
    }

    function showMediaTranscriptions(media) {
      if (media.status === 'transcribed' || media.status === 'translated' || media.status === 'subtitled') {
        transcriptionRestService.getByMedia(media.id).then(response => me.transcriptions = response);
      } else {
        me.transcriptions = [];
      }
    }

    function onClickHome() {
      $window.location.href = ogUrl;
    }

  }
})();
