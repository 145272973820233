(function () {
  'use strict';

  angular
    .module('app')
    .factory('projectPublishedService', projectPublishedService);

  projectPublishedService.$inject = ['STORAGE_BASE_URL', '$http']

  function projectPublishedService(STORAGE_BASE_URL, $http) {
    const service = {
      getOne: getOne
    };
    return service;
    /////////////////////

    function getOne(id) {
      return $http.get(STORAGE_BASE_URL + 'projects/' + id).then(response => response.data);
    }

  }
})();
